import Card from "components/card";
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    deleteProduct,
    duplicateProduct,
    getProduct,
    updateProduct,
} from "interfaces/products";
import { useDisclosure } from "@chakra-ui/hooks";
import StockEntryModal from "../stockList/components/StockEntryModal";
import StockExitModal from "../stockList/components/StockExitModal";
import DeleteModal from "components/actions/Delete";
import ProductImageWidget from "./components/ProductImageWidget";
import GoBack from "components/actions/GoBack";
import mobilityImg from "assets/img/mobility.png";
import SaveStatus from "components/actions/SaveStatus";
import ProductCommandSettings from "./components/ProductCommandSettings";
import { GoDuplicate } from "react-icons/go";

const UpdateProduct = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [product, setProduct] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [saveStatus, setSaveStatus] = useState<any>("none");
    const [duplicating, setDuplicating] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const {
        isOpen: isOpenExit,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onOpen: onOpenExit,
        onClose: onCloseExit,
    } = useDisclosure();
    const {
        isOpen: isOpenDelete,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure();

    const handleChange = (key: string, value: string) => {
        setSaveStatus("saving");
        setProduct({ ...product, [key]: value });
    };

    const initProduct = async (id: string) => {
        const res: any = await getProduct(id);
        if (!res) {
            navigate("/auth/login");
            return;
        }
        setProduct(res);
    };

    const refreshData = async () => {
        initProduct(params.id);
    };

    const handleSubmit = async () => {
        const newProduct = {
            name: product.name,
            unity: product.unity,
            alert: Number(product.alert),
            _id: product._id,
            ref: product.ref,
            quantity: product.quantity,
            price: product.price,
            daysOfConservation: product.daysOfConservation,
            dlcAlert: product.dlcAlert,
            vat: Number(product.vat),
            description: product.description,
            category: product.category,
        };
        const res = await updateProduct(newProduct);
        if (res) setSaveStatus("saved");
        else setSaveStatus("error");
    };

    const duplicate = async () => {
        setDuplicating(true);
        const res = await duplicateProduct(product._id);
        if (res) {
            setDuplicating(false);
            window.open(`/update-product/${res._id}`, "_blank");
        }
    };

    const delProduct = async (id: string) => {
        const res = await deleteProduct(id);
        if (res) {
            navigate("/stock/stock-list");
        }
    };

    useEffect(() => {
        // Skip useless saving
        if (!loaded && product) {
            setLoaded(true);
            return;
        }

        const delayDebounceFn = setTimeout(async () => {
            handleSubmit();
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/auth/login");
        }
        initProduct(params.id);
        const queryParameters = new URLSearchParams(window.location.search);
        const page = queryParameters.get("action");
        if (page && page === "entry") {
            onOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-1 flex justify-between">
                <GoBack text="Liste des produits" />
                <SaveStatus status={saveStatus} />
            </div>
            <div className="grid grid-cols-12 gap-3">
                <Card extra="col-span-12 sm:col-span-6 mt-5 px-5 border py-5">
                    <div className="grid grid-cols-2 gap-3">
                        <h4 className="mb-[20px] text-lg font-medium">
                            {" "}
                            Produit
                        </h4>
                        {product && product?.type !== "brut" && (
                            <div className="col-span-2 col-start-8">
                                <div className="flex gap-2">
                                    <button
                                        className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                        onClick={() =>
                                            navigate(
                                                `/handle-comp/${product?._id}`,
                                            )
                                        }
                                    >
                                        Modifier les composants
                                    </button>
                                    <button
                                        className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                        onClick={duplicate}
                                        disabled={duplicating}
                                    >
                                        Dupliquer
                                        <GoDuplicate
                                            size={15}
                                            className="ml-2 text-[#01499b]"
                                        />
                                    </button>
                                </div>
                            </div>
                        )}
                        {/* <div className="col-span-2 col-start-10">
                    <button
                        className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-2 py-2 text-xs text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                        onClick={onOpenDelete}
                    >
                        Supprimer le produit
                    </button>
                </div> */}
                    </div>
                    <DeleteModal
                        isOpen={isOpenDelete}
                        onClose={onCloseDelete}
                        description="Êtes-vous sûr de vouloir supprimer ce produit ?"
                        context="produit"
                        deleteFunction={() => {
                            delProduct(product?._id);
                        }}
                    />
                    <StockEntryModal
                        onClose={onClose}
                        isOpen={isOpen}
                        stock={product ? [product] : []}
                        onDone={refreshData}
                    />
                    <StockExitModal
                        onClose={onCloseExit}
                        isOpen={isOpenExit}
                        stock={product ? [product] : []}
                        onDone={refreshData}
                    />
                    <div className="col-span-10 mb-2 mt-3 grid grid-cols-3 gap-3">
                        <InputField
                            label="Produit"
                            placeholder="Chargement..."
                            id="name"
                            type="text"
                            value={product?.name}
                            onChange={(e: any) => {
                                handleChange("name", e.target.value);
                            }}
                            required={true}
                            isLoaded={product}
                            inputSmall={true}
                        />
                        <InputField
                            label="Référence"
                            placeholder="Chargement..."
                            id="name"
                            type="text"
                            value={product?.ref}
                            onChange={(e: any) => {
                                handleChange("ref", e.target.value);
                            }}
                            inputSmall={true}
                        />
                        <InputField
                            label="Unité"
                            placeholder="Chargement..."
                            id="unity"
                            type="text"
                            value={product?.unity}
                            onChange={(e: any) => {
                                handleChange("unity", e.target.value);
                            }}
                            inputSmall={true}
                        />
                        <InputField
                            label="Prix unitaire (€)"
                            placeholder="Chargement..."
                            id="name"
                            type="number"
                            value={product?.price}
                            onChange={(e: any) => {
                                handleChange("price", e.target.value);
                            }}
                            inputSmall={true}
                        />
                        <InputField
                            label="TVA (%)*"
                            placeholder="Inconnu"
                            id="vat"
                            type="number"
                            value={product?.vat}
                            onChange={(e: any) => {
                                handleChange("vat", e.target.value);
                            }}
                            required={true}
                            inputSmall={true}
                            isLoaded={product}
                        />
                        <InputField
                            label="Seuil d'alerte"
                            placeholder="Chargement..."
                            id="name"
                            type="number"
                            value={product?.alert}
                            onChange={(e: any) => {
                                handleChange("alert", e.target.value);
                            }}
                            inputSmall={true}
                        />
                        <InputField
                            label="DLC (jours)"
                            placeholder="Chargement..."
                            id="daysOfConservation"
                            type="number"
                            value={product?.daysOfConservation}
                            onChange={(e: any) => {
                                handleChange(
                                    "daysOfConservation",
                                    e.target.value,
                                );
                            }}
                            inputSmall={true}
                        />
                        <InputField
                            label="Alerte DLC proche (jours)"
                            placeholder="0"
                            id="dlcAlert"
                            type="number"
                            value={product?.dlcAlert}
                            onChange={(e: any) => {
                                handleChange("dlcAlert", e.target.value);
                            }}
                            inputSmall={true}
                        />
                    </div>
                </Card>
                <div className="col-span-12 sm:col-span-6">
                    <ProductImageWidget
                        productId={params.id}
                        imageUrl={product?.imageUrl}
                    />
                </div>
                {/* Stock management */}
                <Card extra="col-span-12 sm:col-span-6 px-[30px] pt-5 pb-[40px] flex flex-col border">
                    <div className="flex justify-between">
                        <h4 className="mb-2 text-lg font-medium">
                            {" "}
                            Gestion de stock
                        </h4>
                        <div className="flex gap-2">
                            <div>
                                <button
                                    className="text-xs rounded-xl border-2 border-[#0260CB] px-2 py-1 font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10"
                                    onClick={onOpen}
                                >
                                    Entrer du stock
                                </button>
                            </div>
                            <div>
                                <button
                                    className="text-xs rounded-xl border-2 border-[#0260CB] px-2 py-1 font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10"
                                    onClick={onOpenExit}
                                >
                                    Sortir du stock
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-5 grid grid-cols-12 gap-5">
                        {["Quantité", "DLC", "Zone", "Lot"].map(
                            (item, index) => (
                                <div className={`col-span-3`}>
                                    <p className="text-xs font-bold md:text-sm">
                                        {item}
                                    </p>
                                </div>
                            ),
                        )}
                        {product &&
                            product.inventory &&
                            product.inventory
                                .filter((i: any) => i.quantity !== 0)
                                .map((item: any, index: any) => (
                                    <>
                                        {[
                                            item.quantity,
                                            item.dlc
                                                ? item.dlc.split("T")[0]
                                                : "Aucune DLC",
                                            item.area || "zone-a",
                                            item.lotNumber || "Inconnu",
                                        ].map((item, index) => (
                                            <div className="col-span-3 mt-4">
                                                <p className="text-xs md:text-sm">
                                                    {item}
                                                </p>
                                            </div>
                                        ))}
                                    </>
                                ))}
                        {product &&
                            product.inventory &&
                            product.inventory.filter(
                                (i: any) => i.quantity !== 0,
                            ).length === 0 && (
                                <div className="col-span-12 mt-10">
                                    <img
                                        className="mx-auto flex h-[120px] w-[120px] items-center justify-center"
                                        src={mobilityImg}
                                        alt=""
                                    />
                                    <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                                        <button
                                            className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                            onClick={onOpen}
                                        >
                                            Entrer du stock
                                        </button>
                                        <button
                                            className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-11"
                                            onClick={onOpenExit}
                                        >
                                            Sortir du stock
                                        </button>
                                    </div>
                                </div>
                            )}
                    </div>
                </Card>
                <div className="col-span-12 sm:col-span-6">
                    <ProductCommandSettings
                        product={product}
                        handleChange={handleChange}
                    />
                </div>
            </div>
        </>
    );
};

export default UpdateProduct;
